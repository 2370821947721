import * as Sentry from '@sentry/react';
import { store } from 'store';
import type {
    BaseQueryApi,
    FetchArgs,
} from '@reduxjs/toolkit/query'

function captureSentryError(error: any, query?: string | FetchArgs, api?: BaseQueryApi) {
    const { user, token, FirstName, LastName } = store.getState().auth

    const customizedTags: any = {
        FirstName,
        LastName,
        token,
        ...user
    };

    const extraDetails = {
        payload: query,
        api,
        ...customizedTags,
    };

    for (const key in customizedTags) {
        const value = customizedTags[key];
        Sentry.setTag(`${key}`, value);
    }

    const errorDetails = {
        status: error.status,
        data: typeof error.data === 'object' ? JSON.stringify(error.data) : error.data,
    };

    if (!error.status || error.status === "FETCH_ERROR") { // This condition avoids capturing of Bad Request
        return
    }
    
    Sentry.captureException(new Error('API Error'), {
        extra: {
            ...extraDetails,
            error: errorDetails,
        },
    });
}

export default captureSentryError